type AirpazFeatures = {
  icon: string;
  title: string;
  description: string;
  type: string;
};

export default (firstOption?: string, secOption?: string): AirpazFeatures[] => {
  return [
    {
      icon: 'simplifyBooking',
      title: 'feature.title1',
      description: 'feature.desc1',
      type: 'flight',
    },
    {
      icon: 'travelProducts',
      title: 'feature.title2',
      description: 'feature.desc2',
      type: 'all',
    },
    {
      icon: 'exclusiveOffer',
      title: 'feature.title3',
      description: 'feature.desc3',
      type: 'all',
    },
    {
      icon: 'bookingExpert',
      title: 'feature.title4',
      description: 'feature.desc4',
      type: 'all',
    },
    {
      icon: 'customerSupport',
      title: 'feature.title5',
      description: 'feature.desc5',
      type: 'all',
    },
    {
      icon: 'currencyLocal',
      title: 'feature.title6',
      description: 'feature.desc6',
      type: 'hotel',
    },
  ].filter(({ type }) => {
    return type === 'all' || type === firstOption || type === secOption;
  });
};
